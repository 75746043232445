import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Row, Col, Container } from "react-bootstrap";

const Footer = () => {
	const { file } = useStaticQuery(
		graphql`
			query {
				file(name: { eq: "footer-logo" }, sourceInstanceName: { eq: "branding" }) {
					publicURL
				}
			}
		`
	);

	return (
		<footer>
			<Container>
				<Row className="justify-content-between">
					<Col className="footer-copyrights">
						<img src={file.publicURL} alt="Pagely footer logo" className="footer-logo" />
						<p className="align-middle">Copyright 2009-2021 Pagely®</p>
					</Col>
					<Col className="footer-misc col-md-auto">
						<ul className="align-middle">
							<li>
								<a href="https://pagely.com">Main Site</a>
							</li>
							<li>
								<a href="https://support.pagely.com">Documentation</a>
							</li>
							<li>
								<a href="https://pagely.com/legal/terms-of-service/">Legal</a>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
