import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Navbar, Container } from "react-bootstrap";

function TopNav({ currentPath }) {
	const { file, site } = useStaticQuery(
		graphql`
			query {
				file(name: { eq: "header-logo" }, sourceInstanceName: { eq: "branding" }) {
					publicURL
				}
				site {
					siteMetadata {
						branding
					}
				}
			}
		`
	);

	return (
		<Navbar variant="dark" className="navbar-top">
			<Container>
				<Navbar.Brand href="#home">
					<img
						alt="Logo"
						src={file.publicURL}
						className="align-middle"
					/>
				</Navbar.Brand>
			</Container>
		</Navbar>
	);
}

export default TopNav;
