import React from "react";
import PropTypes from "prop-types";
import ReactYouTube from "react-youtube";

const YouTube = ({ videoId, id, className, containerClassName }) => (
	<ReactYouTube videoId={videoId} id={id} className={className} containerClassName={containerClassName} />
);

YouTube.propTypes = {
	videoId: PropTypes.string.isRequired,
	id: PropTypes.string,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
};

YouTube.defaultProps = {
	id: null,
	className: "youtube-embed",
	containerClassName: "",
};

export default YouTube;
