import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import Image from "./image";
import LessonFork from "./lesson-fork";
import Lightbox from "./lightbox";
import YouTube from "./youtube";

const shortcodes = { Link, Helmet, YouTube, Image, LessonFork, Lightbox };

export default ({ children }) => (
	<MDXProvider components={shortcodes}>
		<div id="page-container">{children}</div>
	</MDXProvider>
);
