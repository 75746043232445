import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = (props) => {
	const data = useStaticQuery(graphql`
		query {
			images: allFile(filter: { extension: { in: ["png", "jpg", "jpeg", "gif"] } }) {
				edges {
					node {
						relativePath
						name
						childImageSharp {
							gatsbyImageData
						}
					}
				}
			}
		}
	`);

	const image = data.images.edges.find((i) => {
		return i.node.relativePath.includes(props.src);
	});

	if (!image) {
		return null;
	}

	const imageSizes = image.node.childImageSharp.gatsbyImageData;

	return <GatsbyImage image={imageSizes} alt={props.alt} className={props.className} />;
};

export default Image;
