import React from "react";
import { Modal, Button } from "react-bootstrap";
import Image from "./image";

const Lightbox = (props) => {
	const [lightboxShow, setLightboxShow] = React.useState(false);

	const handleClose = () => setLightboxShow(false);
	const handleShow = () => setLightboxShow(true);

	return (
		<figure>
			<div
				className={`lightbox-wrapper ${props.className}`}
				onClick={handleShow}
				onKeyDown={handleShow}
				role="presentation"
			>
				<Image src={props.src} alt={props.alt} />
			</div>
			<Modal
				show={lightboxShow}
				onHide={handleClose}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<Image src={props.src} alt={props.alt} />
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</figure>
	);
};

export default Lightbox;
